.markdown-text {
	font-size: 1rem;
	color: black;

	&.agreement-text {
		font-family: $agreement-text-font-family;
	}


	h3 {
		margin-top: 2em;
		font-size: 1.35rem;
		font-weight: bolder;
	}

	h4 {
		margin-top: 1.8em;
		font-size: 1.35rem;
		font-weight: bolder;
	}

	h5,
	h6 {
		margin-top: 1.5em;
		font-size: 1.1rem;
		font-weight: bold;
	}

	blockquote {
		top: 0;
		font-size: 1rem;
		border-left: 5px solid rgba(0, 0, 0, 0.2);
		margin-bottom: 1em;
	}

}