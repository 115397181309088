me-droplist.views-droplist {
	width: 100%;
	max-width: 350px;
	margin-bottom: 16px;
	margin-right: 1em;
}

kendo-dropdownlist {

	&.k-picker-solid {
		border-color: rgba(0, 0, 0, 0.25);
		background: #fff;

		&:hover {
			background-color: rgb(var(--rgb-button-hover-back));
		}
	}


	//
	// Our custom class placed on <kendo-dropdownlist>
	//
	&.navigation-droplist {

		padding: 0;
		border-width: 1px !important;
		background: #f5f5f5;

		&:hover {
			transform: none !important; // The normal transform messes with placement of the drop menu
		}

		.k-dropdown-wrap {

			border: none !important;
			background-color: transparent !important;

		}
	}
}


.k-dropdownlist {

	//
	// Fix weird bug with kendo adding "before" content for no apparent
	// reason which makes the droplist component double height.
	//
	.k-input-value-text::before {
		content: unset;
	}

}


.k-list {

	.k-list-group-item,
	.k-list-group-sticky-header {
		background-color: rgba(255, 255, 0, 0.04);
		border-top: solid 1px rgba(0, 0, 0, 0.15);
		border-bottom: solid 1px rgba(0, 0, 0, 0.15);
	}

	.k-list-group-item .k-list-item-text,
	.k-list-group-sticky-header .k-list-header-text {
		font-weight: bold;
	}
}


//
// Background colors of selected items in the dropdownlist popup
//
div.k-list-optionlabel,
ul.k-list li.k-item {
	&:hover {
		color: #000;
		background-color: $row-hover;
	}

	&.k-focused,
	&.k-selected {
		color: #000;
		background-color: $row-hover;
		box-shadow: none;

		&:hover {
			color: #000;
			background-color: $row-hover;
		}
	}
}