.role-badge {
	overflow: hidden;
	display: inline-grid;
	width: 1.3em;
	height: 1.25em;
	line-height: 1.25em;
	margin: -.15em 0 -.15em -.15em;
	font-size: 1em;
	text-align: center;
	vertical-align: middle;
	border-radius: 50%;
	border: solid 1px rgba(0,0,0,0.1);
	color: rgba(0,0,0,0.3);
	user-select: none;

	&.role-badge-entrepreneur {
		background-color: $role-color-entrepreneur;
		border: solid 1px darken($role-color-entrepreneur,10%);
		color: #FFF;
	}

	&.role-badge-mentor {
		background-color: $role-color-mentor;
		border: solid 1px darken($role-color-mentor,10%);
		color: #FFF;
	}

	&.role-badge-facilitator {
		background-color: $role-color-facilitator;
		border: solid 1px darken($role-color-facilitator,10%);
		color: #FFF;
	}

	&.role-badge-specialist {
		background-color: $role-color-specialist;
		border: solid 1px darken($role-color-specialist,10%);
		color: #FFF;
	}

	&.role-badge-reader {
		background-color: $role-color-reader;
		border: solid 1px darken($role-color-reader,10%);
		color: #FFF;
	}

	&.role-badge-interviewer {
		background-color: $role-color-interviewer;
		border: solid 1px darken($role-color-interviewer,10%);
		color: #FFF;
	}

	&.role-badge-judge {
		background-color: $role-color-judge;
		border: solid 1px darken($role-color-judge,10%);
		color: #FFF;
	}

}
