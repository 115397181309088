mat-accordion.simple-accordion {
  display: block;
  border: none !important;
  border-radius: 2px;

  & > mat-expansion-panel {
    background-color: transparent;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0) !important;
    transition: all 0.3s ease;
    margin: 0;

    &:not(:last-child) {
      border-bottom: none !important;
    }

    .mat-expansion-panel-body {
      padding: 0 24px 6px;
    }
    
    mat-expansion-panel-header {
      background-color: transparent;
      transition: all 0.3s ease;
      height: 29px !important;

      &.mat-expanded,
      &.mat-expanded:focus {
        padding: 0 1.2em;
        height: 29px !important;
        // margin: 0.3em 0.3em 1em;
        // padding: 0 1.2em;
        // background-color: #3691;
      }
    }
  }
}
