
.green-box {
	padding: .4em;
	background-color: rgba(0, 128, 0, 0.15);
	border: solid 1px rgba(0, 0, 0, 0.05);
}

.red-box {
	padding: .4em;
	background-color: rgba(255, 0, 0, 0.125);
	border: solid 1px rgba(0, 0, 0, 0.05);
}