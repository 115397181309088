@import "app-variables";

.navigation-tabs {

	margin-bottom: 1.2rem;

	.mat-mdc-tab.mdc-tab {
		height: 32px;
		min-width: 80px;
		border-radius: 4px 16px 0 0;
		padding: 0 8px 0 10px;
		margin: 0 -1px;
		color: #000;
		opacity: 1;
		border-bottom: solid 2px rgb(var(--rgb-accent-back)) !important;
		position: relative;
		left: 1px; // Nudged right relatively so the 2px border doesn't get cut off
		border: solid 1px transparent;
		flex-grow: unset !important;
		letter-spacing: unset;

		&.mdc-tab--active {
			background-color: rgb(var(--rgb-page-back)) !important;
			color: rgb(var(--rgb-accent-back)) !important;
			border: solid 2px rgb(var(--rgb-accent-back)) !important;
			border-bottom: none !important;
		}

		&:hover:not(.mdc-tab--active) {
			border: solid 1px rgba(0, 0, 0, 0.1) !important;
			border-bottom: solid 2px rgb(var(--rgb-accent-back)) !important;
		}

		.mdc-tab__text-label {
			color: #000;
			user-select: none;
		}

		&:focus {
			.mdc-tab__text-label {
				text-shadow: 0 0 black;
			}
		}
	}

	.mdc-tab-indicator {
		display: none;
	}


	.mat-mdc-tab-header {
		border-bottom: none !important;
	}
}