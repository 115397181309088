@import 'app-variables';

.k-panelbar.bare-panelbar {
	border-color: transparent;
	background-color: transparent;

	> .k-panelbar-item {
		border-color: rgba(0, 0, 0, 0.08);

		&.k-expanded {
			> .k-link {
				background-color: rgba(0, 0, 0, 0.05) !important;
			}
		}

		> .k-link {
			background-color: rgba(0, 0, 0, 0.05);
			border-left: solid 3px transparent;
			font-size: 1.3em;
			cursor: pointer;
			color: rgb(var(--rgb-accent-back));
			// display: block;

			&.k-selected,
			&:hover {
				background-color: rgba(0, 0, 0, 0.07);
				color: rgb(var(--rgb-accent-back));
				border-left-color: rgba(0, 0, 0, 0.08);

				&.k-hover {
					background-color: transparent;
				}
			}

		}

		&:focus-within .k-link {
			border-left-color: rgb(var(--rgb-accent-back));
		}

		.k-panelbar-content {
			background-color: transparent;
			border-left: dotted 1px rgba(0, 0, 0, 0.08);
			margin: 0 0 2em 1em;
			padding: 1em;
		}
	}
}

@media (max-width: 544px) {
	.k-panelbar.bare-panelbar > .k-panelbar-item .k-panelbar-content {
		margin: 0 0 1em;
		padding: .25em 0 0 .15em;
	}
}