//
// Common scss for all explorer parts
//

.explorer-part {
	display: flex;
	height: 100%;

	.icon-bar,
	.content {
		height: 100%;
	}

	.content {
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		padding: 0 0.3em 5em 0.2em;

		.explorer-highlight-section {
			padding: 0 3px;
			margin: 0 -2px;
			background-color: lightyellow;
		}
	}


	//
	// All explorer headers have a table on the left and a div container on the right.
	// These classes will auto collapse the right div under the table on small displays.
	//
	.explorer-details-container {
		display: flex;
		flex-direction: row;
		gap: 3px;

		.explorer-details-right {
			border: solid 1px rgba(0, 0, 0, 0.1);
			padding: 0.6em;
			background-color: #fcfcfc;
		}

		table {
			margin: 0;
		}

		> * {
			flex-grow: 1;
		}
	}
}


.explorer-header {
	font-size: 1.6em;
	display: flex;
	justify-content: center;
}

.explorer-sub-header {
	font-size: 1.35em;
	display: flex;
	justify-content: center;
	margin-bottom: 0.4em;

}

.explorer-small-header {
	font-size: 1em;
	display: flex;
	justify-content: center;
	margin-bottom: 0.4em;
}