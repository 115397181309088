fieldset {
	display: inline-block;
	padding: 1em;
	margin-bottom: 1.5em;
	width: 100%;
	border: solid 1px #CCC;
	background: #FFF;
	border-radius: .5em;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;

	> legend {
		font-size: 1.1em;
		width: auto;
		background: #FFF;
		color: #999;
		margin: 0;
		padding: 0 .5em;
		border: solid 1px #CCC;
		border-radius: .2em;
		margin-bottom: .5em;
		float: none;
	}

	button-container {
		white-space: normal;
		text-align: center;
		display: inline-block !important;
		margin: 0 !important;
	}
}

fieldset:hover {
	> legend {
		color: rgb(var(--rgb-accent-back));
	}
}

fieldset:focus-within {
	border-color: rgb(var(--rgb-accent-back));

	> legend {
		background-color: rgb(var(--rgb-accent-back));
		color: rgb(var(--rgb-accent-fore));
		border-color: rgb(var(--rgb-accent-back));
	}
}
