$me-grid-buttons-area-width: 200px;

me-grid {
	div.me-grid-buttons {
		position: relative;
		z-index: 10;

		> div {
			min-height: 52px;
			width: $me-grid-buttons-area-width;
			position: absolute;
			top: 0;
			right: 0;

			> div {
				text-align: right;
				padding-right: 4px;

				.btn {
					margin: 3px;
					height: 30px;
					padding: 4px 7px;
					font-size: 1em;

				}
			}

			> div:nth-child(2) {
				position: relative;
				top: -5px;
				left: -3px;
			}
		}
	}
}

kendo-grid.me-grid.k-grid {
	background-color: transparent;
	border: none;
	border: solid 2px green;

	table.k-grid-table {
		border-collapse: collapse;
	}


	div.k-grid-header {

		background-color: transparent;
		border-top: solid 1px #bbb;
		border-bottom: solid 1px #bbb;

		div.k-grid-header-wrap {
			border: none;
		}

		thead tr {
			th.k-table-th {
				padding: 7px;
				font-weight: bold;
				font-size: 13px;
				border: none;
				color: #000;

				a {
					// font-weight: bold;
					// color: #777;
				}

				&:not(:first-child) {
					border-left: 1px solid #bbb;
				}

				.k-icon.k-i-sort-asc-sm,
				.k-icon.k-i-sort-desc-sm {
					float: left;
				}

				> .k-cell-inner {
					> .k-link {
						margin-right: -9px;
					}
				}
			}
		}
	}


	div.k-grid-content {

		background-color: transparent;

		tbody.k-table-tbody tr.k-table-row {
			cursor: pointer;
			border-bottom: 1px dotted rgba(0, 0, 0, 0.2);

			&.k-table-alt-row:not(:hover):not(.k-selected) {
				background-color: rgb(var(--rgb-page-back));
			}

			&.k-selected {
				background-color: rgb(var(--rgb-accent-back));

				> td {
					background-color: transparent;
					color: rgb(var(--rgb-accent-fore)) !important;

					* {
						color: rgb(var(--rgb-accent-fore)) !important;
					}

					grid-cell-ratings {

						.r2,
						.r3 {
							color: black !important;
						}
					}
				}
			}

			&.k-hover:not(.k-selected) {
				background-color: #f7f7f7;
			}

			td.k-table-td {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding: 2px 2px 2px 7px;
				border-color: transparent;
				user-select: none;
				height: 24px !important;
				min-height: 24px !important;
				max-height: 24px !important;
				box-sizing: border-box !important;

				> div.grid-cell {
					min-height: 19px; // 23px - 2px for borders
					font-size: 13px;
					line-height: 19px;
					margin: 0;
					padding: 3px .5em 1px 0;
					color: #000;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				> div.grid-row-min-height {
					min-height: 33px; // 35px - 2px for borders
					margin: 0;
					padding: 0;

					> p.grid-middle-aligned {
						padding-top: 6px;
					}
				}
			}
		}

	}


	.k-grid-footer td,
	.k-group-footer td,
	.k-grouping-row td {
		font-weight: inherit;
		border-top: 1px solid rgba(0, 0, 0, .2);
		border-bottom: 1px solid rgba(0, 0, 0, .2);
		background-color: rgba(255, 255, 0, 0.05);

		p {
			gap: 4px;
		}
	}


	kendo-grid-group-panel.k-grouping-header {
		background-color: transparent;
		color: #bbb;
		border: none !important;
		margin-right: $me-grid-buttons-area-width;
		max-width: calc(100% - $me-grid-buttons-area-width);
		min-height: 50px;
		flex-flow: row nowrap;
		overflow: hidden;
		cursor: default;

		&:hover {
			background-color: rgba(0, 0, 0, 0.015);
			color: #666;
		}

		kendo-chip.k-chip {
			padding: 0.6em;
			border: solid 1px #ccc;
		}

		div.k-grouping-drop-container {
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.k-group-cell {
		max-width: 5px !important;
		width: 5px !important;
		padding: 0 !important;
	}
}


/**
 *  [class.page-grid-content]="setup.container=='page'"
 */
.k-grid.page-grid-content {
	margin: 0 -18px -18px;
}

.page-grid-buttons-content {
	margin-right: 18px;
}

/**
 * Extra small screens have reduce the horizontal page
 * margin from 18px to 8px so the grid content needs
 * to be adjusted similarly.
 */
@media (max-width: 544px) {
	.k-grid.page-grid-content {
		margin: 0 -8px -8px;
	}

	.page-grid-buttons-content {
		margin-right: 8px;
	}
}


@media (max-width: 675px) {
	kendo-grid.me-grid.k-grid {
		kendo-grid-group-panel.k-grouping-header {
			div.k-grouping-drop-container {
				color: transparent; // Hide the "Drag a column header and drop it..." text on small screens
			}
		}
	}
}