@keyframes page-spinner-keyframes {
	0% {
		transform: rotate(360deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

body.page-is-spinning {
	.mat-dialog-container {
		div.dialog-frame-part {
			opacity: .6;
			cursor: wait;
			pointer-events: none;
			user-select: none;

			* {
				pointer-events: none;
				user-select: none;
			}
		}
	}

	.page-spinner {
		opacity: 1;
		transform: scale(1);
	}

}

.page-spinner {
	font-size: 20vmax;
	line-height: 20vmax;
	opacity: 0;
	transform: scale(0.7);
	transform-origin: 80% 80%;
	transition: opacity .7s, transform 1.5s;
	position: fixed;
	bottom: 1vmin;
	right: 1vmin;
	pointer-events: none;
	user-select: none;
	z-index: 1000;

	i::before {
		color: rgb(var(--rgb-accent-back));
	}

	.ps-icons {

		transform-origin: center;
		display: block;
		position: fixed;
		width: 20vmax;
		height: 20vmax;
		animation: page-spinner-keyframes 0.3s linear reverse 0s infinite;

		> .ps-icon {
			position: absolute;
			left: 0;
			top: 0;
			width: 20vmax;
			height: 20vmax;
			transform-origin: center;
			animation: page-spinner-keyframes 0.5s linear 0s infinite;

			&::before {
				display: inline-block;
			}

			&:nth-child(1) {
				&::before {
					transform: scale(0.13);
				}

				opacity: 1;
				animation-delay: .650s;
			}

			&:nth-child(2) {
				&::before {
					transform: scale(0.18);
				}

				opacity: .85;
				animation-delay: .550s;
			}

			&:nth-child(3) {
				&::before {
					transform: scale(0.25);
				}

				opacity: .7;
				animation-delay: .455s;
			}

			&:nth-child(4) {
				&::before {
					transform: scale(0.35);
				}

				opacity: .55;
				animation-delay: .377s;
			}

			&:nth-child(5) {
				&::before {
					transform: scale(0.48);
				}

				opacity: .4;
				animation-delay: .286s;
			}

			&:nth-child(6) {
				&::before {
					transform: scale(0.66);
				}

				opacity: .25;
				animation-delay: .156s;
			}

			&:nth-child(7) {
				&::before {
					transform: scale(0.9);
				}

				opacity: .1;
				animation-delay: 0s;
			}
		}
	}

	> .page-spinner-desc,
	> .page-spinner-text {
		color: rgb(var(--rgb-accent-back));
		width: 20vmax;
		text-align: center;
		display: block;

		span {
			font-family: "Lucida Console", Monaco, "Courier New", Courier, monospace;
			background-color: rgb(var(--rgb-page-back));
			padding: 7px;
			border-radius: .5rem;
		}
	}

	> .page-spinner-text {
		font-size: .9rem;
		line-height: 20vmax;
		height: 20vmax;
	}

	> .page-spinner-desc {
		margin-top: 3px;
		font-size: .8rem;
		font-weight: bold;
		line-height: 1.1rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		direction: rtl;
		// Put the ellipses on the left side
	}
}