kendo-combobox {

	&.k-input-solid {
		border-color: rgba(0, 0, 0, 0.25);
		background: #fff;

		&:hover {
			background-color: rgb(var(--rgb-button-hover-back));
		}
	}

}