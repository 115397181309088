@import "app-fonts";

.char-code-entry.k-maskedtextbox {

	margin: 3em 0;

	& > .k-textbox {
		font-size: 2em;
		font-family: $monospace-font-family;
		text-transform: uppercase;
		text-align: center;
	}
}
