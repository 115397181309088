.cdk-overlay-dark-backdrop {
	background: rgba(127, 127, 127, 0.4);
	-webkit-backdrop-filter: blur(4px) saturate(30%) contrast(60%);
	backdrop-filter: blur(4px) saturate(30%) contrast(60%);

	.mat-mdc-dialog-container {
		border-radius: 0;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
	}
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	display: block;
}


.mat-dialog-actions {
	min-height: unset !important;
}