kendo-scheduler {
	&.no-today-navigation {
		.k-scheduler-navigation {
			display: none;
		}
	}

	&.style-date-nav-as-button .k-nav-current.k-button.k-button-flat-base.k-button-flat {
		border-color: rgba(0, 0, 0, 0.08) !important;
		color: #424242 !important;
		background-color: #f5f5f5 !important;
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02)) !important;
	}


	agenda-view-internal {
		.k-scheduler-table th:not(.k-scheduler-datecolumn):not(.k-scheduler-timecolumn) {
			width: 13em;
		}

		.k-scheduler-table td:not(.k-scheduler-datecolumn):not(.k-scheduler-timecolumn) {
			width: 13em;
		}

		.k-scheduler-datecolumn {
			width: 8em;
		}

		.k-scheduler-timecolumn {
			width: 7em;
		}
	}


}